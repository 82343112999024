import React from 'react';
import * as d3 from "d3";
import './chart.css';

const DayOfTheWeek  = {0: 'niedziela', 1: 'poniedziałek', 2: 'wtorek', 3: 'środa', 4: 'czwartek', 5: 'piątek', 6: 'sobota'};
const Activities = {0: 'Postój', 1: 'Dyspozycyjność', 2: 'Praca', 3: 'Prowadzenie', 9: 'Brak danych'};

const 	displayDays			= 300,
		wrapperId			= 'ResmChartDAD',
		daySize				= {width: 845, height: 100},
		dayMargins 			= {left: 10, right: 30, top: 10, bottom: 1},
		dayLabel			= {width: 75},
		dayAxis				= {height: 25},
		dayPlace			= {height: 45},
		dayDdtStart			= {height: 65},
		activitiesHeight	= {3: 40, 2: 30, 1: 20, 0: 6, 9: 6};


export default class ReaderChartDAD_ extends React.Component {
	constructor(props) {
		super(props);
		let td = new Date(this.props.activityData.activityRecords[0].start);
		this.oldestDay = new Date(td.getFullYear(), td.getMonth(), td.getDate(), 0, 0, 0, 0).getTime();
		td = new Date(this.props.activityData.activityRecords[this.props.activityData.activityRecords.length-1].end);
		this.newestDay = new Date(td.getFullYear(), td.getMonth(), td.getDate(), 0, 0, 0, 0).getTime();
		this.currentDay = this.newestDay;
		this.oldestDisplayDay = 0;
		this.newestDisplayDay = 0;
		
		this.toEnd = false;
		this.drawChart = this.drawChart.bind(this);
		this.scrollToEnd = this.scrollToEnd.bind(this);
		this.onScroll = this.onScroll.bind(this);
		this.onMouseOver = this.onMouseOver.bind(this);
		this.onClick = this.onClick.bind(this);

	}
	
	componentDidMount(){
		document.getElementById(wrapperId).addEventListener('scroll', this.onScroll);
		document.getElementById(wrapperId).addEventListener('mouseover', this.onMouseOver);
		document.getElementById(wrapperId).addEventListener('click', this.onClick);
		this.toEnd = true;
		this.drawChart();
		this.scrollToEnd();
	}


	/*
	shouldComponentUpdate(nextProps) {

		if (nextProps.activityData !== this.props.activityData)
			return true;
		if (this.toEnd)
			return true;
		
		return false;
	}
	*/

	componentDidUpdate(){
		this.drawChart();
		if (this.toEnd)
			this.scrollToEnd();
	}

	componentWillUnmount(){
		document.getElementById(wrapperId).removeEventListener('scroll', this.onScroll);
		document.getElementById(wrapperId).removeEventListener('mouseover', this.onMouseOver);
		document.getElementById(wrapperId).removeEventListener('click', this.onClick);
	}

	render() {
		
		return (
			<div className="ResmChartDAD">
				<div>
					<div id={wrapperId} ></div>
				</div>
			</div>
		);
	}

	drawChart(){
		let td = new Date(this.currentDay);
		
		document.getElementById(wrapperId).innerHTML ='';

		if (Object.keys(this.props.activityData).length === 0)
			return;
			
		if (this.currentDay === this.newestDay){
            this.newestDisplayDay = this.newestDay;
            this.oldestDisplayDay = new Date(td.getFullYear(), td.getMonth(), td.getDate() - displayDays, 0, 0, 0, 0).getTime();
        }else if (this.currentDay === this.oldestDay){
            this.oldestDisplayDay = this.oldestDay;
            this.newestDisplayDay = new Date(td.getFullYear(), td.getMonth(), td.getDate() + displayDays, 0, 0, 0, 0).getTime();
        } else {
            this.oldestDisplayDay = new Date(td.getFullYear(), td.getMonth(), td.getDate() - Math.floor(displayDays/2), 0, 0, 0, 0).getTime();
            this.newestDisplayDay = new Date(td.getFullYear(), td.getMonth(), td.getDate() + Math.floor(displayDays/2), 0, 0, 0, 0).getTime();
		}
		
		this.oldestDisplayDay = this.oldestDisplayDay < this.oldestDay ? this.oldestDay : this.oldestDisplayDay;
        this.newestDisplayDay = this.newestDisplayDay > this.newestDay ? this.newestDay : this.newestDisplayDay;  
		
        for(let key in this.props.activityData.activityRecords) {
			
            let td = new Date(this.props.activityData.activityRecords[key].start),
   				startOfDayStart = new Date(td.getFullYear(), td.getMonth(), td.getDate(), 0, 0, 0, 0); //.getTime() / 1000;
                
            td = new Date(this.props.activityData.activityRecords[key].end);
            let startOfDayEnd = new Date(td.getFullYear(), td.getMonth(), td.getDate(), 0, 0, 0, 0),  //.getTime() / 1000;
				  elCount = Math.floor(((startOfDayEnd.getTime()-(startOfDayEnd.getTimezoneOffset()*60*1000)) - (startOfDayStart.getTime()-(startOfDayStart.getTimezoneOffset()*60*1000)))/(1000*3600*24));
            
            for (let j=0; j<=elCount; j++) {
				td = new Date(this.props.activityData.activityRecords[key].start);
                startOfDayStart = new Date(td.getFullYear(), td.getMonth(), td.getDate() + j, 0, 0, 0, 0).getTime();


                if (startOfDayStart < this.oldestDisplayDay || startOfDayStart > this.newestDisplayDay)
                    continue;
                createActivity(this.props.activityData.activityRecords[key], startOfDayStart);
            }
		}
		
		/*
		if (this.props.activityData.violations){
			for(let key in this.props.activityData.violations){
				let td = new Date(this.props.activityData.violations[key].start),
					startOfDayStart = new Date(td.getFullYear(), td.getMonth(), td.getDate(), 0, 0, 0, 0); //.getTime() / 1000;
				td = new Date(this.props.activityData.violations[key].end);
				let	startOfDayEnd = new Date(td.getFullYear(), td.getMonth(), td.getDate(), 0, 0, 0, 0); //.getTime() / 1000;
				
				let elCount = Math.floor(((startOfDayEnd.getTime()-(startOfDayEnd.getTimezoneOffset()*60*1000)) - (startOfDayStart.getTime()-(startOfDayStart.getTimezoneOffset()*60*1000)))/(1000*3600*24));
				
				for (var j=0; j<=elCount; j++) {
					td = new Date(this.props.activityData.violations[key].start);
					startOfDayStart = new Date(td.getFullYear(), td.getMonth(), td.getDate() + j, 0, 0, 0, 0).getTime();

					if (startOfDayStart < this.oldestDisplayDay || startOfDayStart > this.newestDisplayDay)
						continue;
					//createViolationLine(this.props.activityData.violations[key], startOfDayStart);
				}		
			}
		}
		*/
		//this.props.activityData.placeRecords.forEach((record)=>{
		//	createPlace(record);
		//});

		//this.props.activityData.specificConditions.forEach((record)=>{
		//	createSpecificCondition(record);
		//});

		//if ('dailyDrivingTimes' in this.props.activityData)
		//	this.props.activityData.dailyDrivingTimes.forEach((record)=>{
		//		createDailyDrivingTimeStart(record);
		//});

		//this.props.activityData.gnssRecords.forEach((record)=>{
		//	createGnss(record);
		//});		

	}

	scrollToEnd(){
        let el = document.getElementById(wrapperId);
        el.scrollTop = el.scrollHeight-el.clientHeight-1;
        this.toEnd = false;
	}
	
	onScroll(event){
		if(event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight){
			if (this.newestDisplayDay >= this.newestDay)
				return;
			this.currentDay = this.newestDisplayDay;
			this.drawChart();
			if (document.getElementById('ResmChartDay-' + d3.timeFormat("%Y-%m-%d")(this.currentDay)))
				document.getElementById('ResmChartDay-' + d3.timeFormat("%Y-%m-%d")(this.currentDay)).scrollIntoView(false);
		}
	
		if(event.target.scrollTop === 0){
			if (this.oldestDisplayDay <= this.oldestDay)
				return;
			this.currentDay = this.oldestDisplayDay;
			this.drawChart();
			if (document.getElementById('ResmChartDay-' + d3.timeFormat("%Y-%m-%d")(this.currentDay)))
				document.getElementById('ResmChartDay-' + d3.timeFormat("%Y-%m-%d")(this.currentDay)).scrollIntoView(true);
		}
	}

	onClick(event){
		if (event.target.classList.contains('ResmChartActivityContainer')){
			
			let index = this.props.activityData.activityRecords.findIndex((el)=>{
				if (el.start === parseInt(event.target.getAttribute('start')) && el.end === parseInt(event.target.getAttribute('end')))
					return true;
				return false;
			});

			if (index >= 0){
				this.props.activityData.activityRecords[index].activity = 9;
				this.props.aReaderDriverActivityData(JSON.parse(JSON.stringify(this.props.activityData)));
			}
			
		}
	}

	onMouseOver(event){
		let els = document.querySelectorAll('.ResmChartActivity.hover');
		Array.prototype.forEach.call(els, function(el) {
			el.classList.remove('hover');
		});
		if (event.target.classList.contains('ResmChartActivityContainer')){
			els = document.getElementsByClassName(event.target.getAttribute('start'));
			Array.prototype.forEach.call(els, function(el) {
				el.classList.add('hover');
			});
		}
	}

}

function createDay(startOfDay){
	const 	dayString   = d3.timeFormat("%Y-%m-%d")(startOfDay),
			endOfDay    = new Date(parseInt(dayString.substr(0,4)),(parseInt(dayString.substr(5,2)) - 1),parseInt(dayString.substr(8,2)), 23, 59, 0, 0).getTime(),
			id          = 'ResmChartDay-' + dayString,
			x           = d3.scaleTime()
								.domain([startOfDay, endOfDay])
								.range([0, daySize.width - dayMargins.left - dayMargins.right - dayLabel.width]),
			xAxis       = d3.axisBottom(x) 
								.ticks(d3.timeHour.every(1))
								.tickFormat(d3.timeFormat('%-H'))
								.tickSize(5)
								.tickPadding(5),
			svg         = d3.select('#' + wrapperId).append('svg')
                                .attr('version', '1.1')
                                .attr('id', id)
                                .attr('xmlns', 'http://www.w3.org/2000/svg')
                                .attr('viewBox', '0 0 ' + daySize.width + ' ' + daySize.height)
                                .attr('preserveAspectRatio', 'xMidYMid meet'),
			labContent	= svg.append('g'),
			svgContent	= svg.append('g')
								.attr('transform', 'translate(' + (dayMargins.left + dayLabel.width) +', ' + 0 + ')'),
			dayContent	= svgContent.append('g')
										.attr('class', 'ResmChartDayContent'),
			axisContent	= svgContent.append('g');


	axisContent
		.append('g')
			.attr('class', 'ResmChartAxis')
		   	.attr('transform', 'translate(0, ' + (daySize.height - dayMargins.bottom - dayAxis.height) + ')')
			.call(xAxis)
			.selectAll('text');
	
	dayContent
		.append('rect')
			.attr('class', 'ResmChartActivity-9')
			.attr('x',  0)
			.attr('y', 	daySize.height - dayMargins.bottom - dayAxis.height - activitiesHeight[9])
			.attr('width', daySize.width - dayMargins.left - dayMargins.right - dayLabel.width)
			.attr('height', activitiesHeight[9]);	
	labContent
		.append('text')
			.attr('class', 'ResmChartDayLabel')
			.attr('x', dayMargins.left)
			.attr('y', (daySize.height / 2 - 8))
			.text(d3.timeFormat('%Y-%m-%d')(startOfDay));		

	labContent
		.append('text')
			.attr('class', 'ResmChartDayLabel')
			.attr('x', dayMargins.left)
			.attr('y', (daySize.height / 2 + 8))
			.text(DayOfTheWeek[d3.timeFormat('%w')(startOfDay)]);
	
	return id;
}

function createActivity(activity, startOfDay){
	const 	dayString   = d3.timeFormat("%Y-%m-%d")(startOfDay),
			endOfDay    = new Date(parseInt(dayString.substr(0,4)),(parseInt(dayString.substr(5,2)) - 1),parseInt(dayString.substr(8,2)), 23, 59, 0, 0).getTime(),
			idDay       = 'ResmChartDay-' + dayString,
			start       = activity.start < startOfDay ? startOfDay : activity.start,
            end         = activity.end > endOfDay ? endOfDay : activity.end;

	if (!document.getElementById(idDay))
		createDay(startOfDay);

	const 	dayRef    	= d3.select('#'+idDay),
			x           = d3.scaleTime()
								.domain([startOfDay, endOfDay])
								.range([0, daySize.width - dayMargins.left - dayMargins.right - dayLabel.width]),
			actGroup   	= dayRef.select('.ResmChartDayContent')
									.append('g')
										.attr('transform', 'translate(' + x(start) + ',0)')
										.attr('class', 'ResmChartActivityGroup-'+ activity.activity),
			actWidth	= (x(end) > daySize.width-dayMargins.left-dayMargins.right) ? daySize.width-dayMargins.left-dayMargins.right - x(start) : x(end) - x(start),
			duration    = (activity.end - activity.start) / 60 / 1000,
            hours       =  Math.floor(duration/60),
            minutes     = ('0'+(duration - (hours*60))).slice(-2);

	let		title		= '';


	title    = Activities[activity.activity];
    title   += activity.ferry ? ' (Prom)' : '';
    title   += activity.out ? ' (OUT)' : '';
    title   += '\r\nStart: ' + d3.timeFormat('%Y-%m-%d %H:%M')(activity.start) + '\r\nKoniec: ' + d3.timeFormat('%Y-%m-%d %H:%M')(activity.end);
    title   += '\r\nCzas trwania: '+ hours + ':' + minutes;
    title   += activity.inProgress ? ' (Aktywnośćść niezakończona - odczyt karty w tachografie)' : '';
    title   += activity.vehicle ? '\r\nSamochód: ' + activity.vehicle : '';
    title   += activity.drivingStatus && activity.drivingStatus === 1 ? '\r\nZałoga' : '';
    title   += activity.drivingStatus && activity.drivingStatus === 11 ? '\r\nWpis manualny' : '';

	
	if (activity.weeks){
		activity.weeks.forEach(function(rec){
			if (rec.compensatingRest)
				title += '\r\nRekompensata w odpoczynku: ' + d3.timeFormat("%Y-%m-%d %H:%M")(rec.compensatingRest);
		});
	}

	if (activity.compensations && activity.compensations.constructor === Array){
		activity.compensations.forEach(function(rec){
			let hoursC =  Math.floor(rec.compensation/60),
				minutesC = rec.compensation - (hoursC*60);
			title += '\r\nRekompensata ('+hoursC+':'+minutesC+') odpoczynku z dnia: ' + d3.timeFormat("%Y-%m-%d %H:%M")(rec.restStart);
		});
	}

	actGroup.append('title').text(title);

	actGroup.append('rect')
		.attr('class', 'ResmChartActivity ResmChartActivity-' + activity.activity + ' ' + activity.start)
		.attr('y', daySize.height - dayMargins.bottom - dayAxis.height - activitiesHeight[activity.activity])
		.attr('width', actWidth)
		.attr('height', activitiesHeight[activity.activity]);		

    if (activity.weeks){    
    	for (let x in activity.weeks) {
            let weekStart = new Date(activity.weeks[x].weekStart),
	            weekEnd = new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() + 7, 0, 0, 0, 0).getTime();
            weekStart = weekStart.getTime();

            if (start >= weekStart && end <= weekEnd){
                actGroup.append('rect')
							.attr('class', 'ResmChartActivity ResmChartActivity-weeklyRestThisWeek ' + activity.start)
							.attr('y',  daySize.height - dayMargins.bottom - dayAxis.height - (activitiesHeight[activity.activity] / 3))
							.attr('width', actWidth)
							.attr('height', activitiesHeight[activity.activity] / 3);
            }      
        }
    }
	
	/* załoga */
	if (activity.drivingStatus && activity.drivingStatus === 1){
		actGroup.append('rect')
					.attr('class', 'ResmChartActivityCrew')
					.attr('x',0)
					.attr('y',daySize.height - dayMargins.bottom - dayAxis.height-0)
					.attr('width',actWidth)
					.attr('height',4);
		actGroup.append('line')
					.attr('class', 'ResmChartActivityCrewLine')
					.attr('x1',0)
					.attr('y1',daySize.height - dayMargins.bottom - dayAxis.height+3)
					.attr('x2',actWidth)
					.attr('y2',daySize.height - dayMargins.bottom - dayAxis.height+3);
	}

	if (actWidth > 20) {
		const label = actGroup.append('g')
						.attr('class', 'ResmChartActivityLabel'),
			  pikto = label.append('g')
			  			.attr('transform', 'translate(' + (actWidth/2 - 5) + ',' + (daySize.height - dayMargins.bottom - dayAxis.height - 30) +')'),
			  dailyRestReducedCount = actWidth > 40 ? (activity.dailyRestReducedCount ? ' (' + activity.dailyRestReducedCount.toString() + 'x9)' : '') : '';
		
		if (activity.weeklyRest) {
			label.append('text')
					.attr('class', 'ResmChartActivityLabel')
					.attr('x', actWidth/2)
					.attr('y', daySize.height - dayMargins.bottom - dayAxis.height - 35)
					.text('OT: ' + activity.weeklyRest);			
		}
					
		label.append('text')
				.attr('class', 'ResmChartActivityLabel')
				.attr('x', actWidth/2)
				.attr('y', daySize.height - dayMargins.bottom - dayAxis.height - 10)
				.text(hours + ':' + minutes + dailyRestReducedCount);

						
		switch(activity.activity) {
			case 3:
				pikto.append ('path').attr('d','M5,0C2.239,0,0,2.239,0,5c0,2.762,2.239,5,5,5c2.762,0,5-2.238,5-5C10,2.239,7.762,0,5,0z M5,8.5 C3.07,8.5,1.5,6.93,1.5,5S3.07,1.5,5,1.5S8.5,3.07,8.5,5S6.93,8.5,5,8.5z')
				pikto.append('circle').attr('cy',5).attr('cx',5).attr('r',1);
				break;
			case 2:
				pikto.append('rect').attr('x',-1).attr('y',5).attr('transform','matrix(0.7071 -0.7071 0.7071 0.7071 -3.0244 4.5669)').attr('width','10').attr('height','1.5');
				pikto.append('rect').attr('x',1).attr('y',5).attr('transform','matrix(-0.7072 -0.707 0.707 -0.7072 6.0456 14.3727)').attr('width','10').attr('height','1.5');
				pikto.append('rect').attr('x',0.121).attr('y',1.121).attr('transform','matrix(-0.7071 0.7071 -0.7071 -0.7071 5.1206 2.1207)').attr('width','4').attr('height','2');
				pikto.append('rect').attr('x',5.879).attr('y',1.121).attr('transform','matrix(0.707 0.7072 -0.7072 0.707 3.8 -4.9503)').attr('width','4').attr('height','2');
				break;
			case 1:
				pikto.append ('path').attr('d','M0,0v10h10V0H0z M8.5,2.5v6h-6L8.5,2.5z M1.5,7.5v-6h6L1.5,7.5z');
				break;
			case 0:
				if (activity.ferry) {
					pikto.append('polygon').attr('points', '-2.5,4.5 6,4.5 6,10 4.5,10 4.5,6 -2.5,6 -2.5,10 -4,10 -4,0 -2.5,0 ');
					pikto.append ('path').attr('d', 'M10.601-0.75v-2.8H9.024V-6H7.976v2.45H6.399v2.8H5V0.3c0,1.933,1.566,3.499,3.5,3.499 c1.933,0,3.5-1.565,3.5-3.499v-1.05H10.601z M7.451-2.5h2.098v1.75H7.451V-2.5z M8.5,2.75c-1.35,0-2.45-1.099-2.45-2.45h4.901 C10.951,1.651,9.85,2.75,8.5,2.75z');
				} else {
					pikto.append('polygon').attr('points','1.5,4.5 10,4.5 10,10 8.5,10 8.5,6 1.5,6 1.5,10 0,10 0,0 1.5,0');
				}
				break;								
			default:
				pikto.append ('path').attr('d','M4.156,7.157c0.009-0.533,0.07-0.954,0.184-1.263c0.114-0.309,0.347-0.65,0.697-1.026l0.896-0.907 C6.316,3.536,6.508,3.08,6.508,2.592c0-0.469-0.126-0.837-0.377-1.104c-0.25-0.267-0.615-0.399-1.094-0.399 c-0.465,0-0.838,0.121-1.121,0.363C3.634,1.694,3.492,2.02,3.492,2.428H2.229C2.237,1.701,2.5,1.115,3.018,0.669S4.208,0,5.037,0 C5.898,0,6.57,0.228,7.051,0.682s0.721,1.077,0.721,1.87c0,0.782-0.369,1.555-1.107,2.316L5.92,5.593 c-0.333,0.363-0.5,0.884-0.5,1.564H4.156z M4.102,9.294c0-0.204,0.063-0.376,0.188-0.514S4.6,8.574,4.846,8.574 S5.279,8.643,5.406,8.78c0.128,0.138,0.191,0.31,0.191,0.514c0,0.202-0.063,0.372-0.191,0.505C5.279,9.933,5.092,10,4.846,10 S4.414,9.933,4.289,9.799C4.164,9.666,4.102,9.496,4.102,9.294z');
		} 	
		//wpis manualny
		if (activity.drivingStatus && activity.drivingStatus === 11)
			label.append ('path')
					.attr('transform', 'translate(' + (actWidth/2-5) + ','+ (daySize.height - dayMargins.bottom - dayAxis.height - 60) +')')
					.attr('d', 'M5.357,1.462V2.69c0.062-0.006,0.117-0.011,0.179-0.011c0.363,0,0.704,0.139,0.966,0.385 c0.174-0.078,0.363-0.117,0.553-0.117c0.402,0,0.776,0.179,1.028,0.486c0.105-0.028,0.206-0.039,0.313-0.039 c0.704,0,1.251,0.586,1.251,1.278v1.211c0,0.417-0.05,0.842-0.156,1.254L8.977,9.19C8.859,9.666,8.429,10,7.938,10h-4.01 C3.48,10,3.051,9.782,2.783,9.432L0.638,6.573C0.454,6.328,0.354,6.021,0.354,5.714c0-0.781,0.637-1.429,1.419-1.429 c0.257,0,0.508,0.067,0.726,0.195V1.429C2.498,0.642,3.141,0,3.928,0C4.727,0,5.357,0.669,5.357,1.462z M3.213,1.429v2.857v2.143 L2.369,5.302C2.23,5.117,2.007,5,1.772,5C1.381,5,1.068,5.329,1.068,5.714c0,0.156,0.05,0.306,0.146,0.429l2.145,2.857 C3.492,9.18,3.704,9.286,3.928,9.286h4.01c0.162,0,0.308-0.112,0.347-0.269l0.514-2.054c0.089-0.356,0.134-0.718,0.134-1.082V4.671 c0-0.296-0.229-0.564-0.536-0.564c-0.296,0-0.536,0.24-0.536,0.536H7.681V4.302c0-0.351-0.269-0.642-0.626-0.642 c-0.346,0-0.625,0.279-0.625,0.625v0.357H6.251V4.141c0-0.402-0.308-0.748-0.715-0.748c-0.396,0-0.715,0.318-0.715,0.714v0.536 H4.643V1.462c0-0.402-0.308-0.748-0.715-0.748C3.531,0.714,3.213,1.032,3.213,1.429z M4.643,5.714H4.464v2.143h0.179V5.714z M6.072,5.714H5.894v2.143h0.179V5.714z M7.502,5.714H7.323v2.143h0.179V5.714z');
	}

	actGroup.append('rect')
		.attr('class', 'ResmChartActivityContainer')
		.attr('y', daySize.height - dayMargins.bottom - dayAxis.height - activitiesHeight[3])
		.attr('width', actWidth)
		.attr('height', activitiesHeight[3])
		.attr('start', activity.start)
		.attr('end',activity.end)
		.attr('type',activity.activity);


}

/*
function createDailyDrivingTimeStart(ddt) {
	const dayString   = d3.timeFormat("%Y-%m-%d")(ddt.start),
		  idDay       = 'ResmChartDay-'+dayString,
		  startOfDay  = new Date(parseInt(dayString.substr(0,4)),(parseInt(dayString.substr(5,2)) - 1),parseInt(dayString.substr(8,2)), 0, 0, 0, 0).getTime(),
		  endOfDay    = new Date(parseInt(dayString.substr(0,4)),(parseInt(dayString.substr(5,2)) - 1),parseInt(dayString.substr(8,2)), 23, 59, 0, 0).getTime();

	if (!document.getElementById(idDay))
		return;

	const day = d3.select('#'+idDay),
		  x = d3.scaleTime()
					.domain([startOfDay, endOfDay])
					.range([0, daySize.width - dayMargins.left - dayMargins.right - dayLabel.width]),
		  title = 'Rozpoczęcie dziennego okresu prowadzenia pojazdu\r\n' + d3.timeFormat("%Y-%m-%d %H:%M")(ddt.start),
		  dailyDrivingTime = ddt.dailyDrivingTime || 0,
		  hours =   Math.floor(dailyDrivingTime/60),		
		  minutes = ('0'+(dailyDrivingTime - (hours*60))).slice(-2),
		  txt = hours + ':' + minutes + (ddt.drivingTimeExtendedCount ? ' (' + ddt.drivingTimeExtendedCount + 'x10)' : ''),
		  sc = day.select('.ResmChartDayContent').append('g')
					.attr('class', 'ResmChartDailyDrivingTimeStart')
					.attr('transform', 'translate(' + x(ddt.start) + ',5)');

	sc.append('title').text(title);

	sc.append('line')
		.attr('x1',0)
		.attr('y1',daySize.height - dayMargins.bottom - dayAxis.height)
		.attr('x2',0)
		.attr('y2',daySize.height - dayMargins.bottom - dayAxis.height - dayDdtStart.height);

	sc.append('rect')
		.attr('y', daySize.height - dayMargins.bottom - dayAxis.height - dayDdtStart.height)
		.attr('width', 35)
		.attr('height', 15)
		.attr('opacity', 0);
		
	sc.append ('path')
		.attr('transform', 'translate(6,'+(daySize.height - dayMargins.bottom - dayAxis.height - dayDdtStart.height)+'), scale(0.8)')
		.attr('d','M5,0C2.239,0,0,2.239,0,5c0,2.762,2.239,5,5,5c2.762,0,5-2.238,5-5C10,2.239,7.762,0,5,0z M5,8.5 C3.07,8.5,1.5,6.93,1.5,5S3.07,1.5,5,1.5S8.5,3.07,8.5,5S6.93,8.5,5,8.5z');
	sc.append('circle')
		.attr('transform', 'translate(6,'+(daySize.height - dayMargins.bottom - dayAxis.height - dayDdtStart.height)+'), scale(0.8)')
		.attr('cy',5).attr('cx',5).attr('r',1);
		
	sc.append('text')
		.attr('y', daySize.height - dayMargins.bottom - dayAxis.height - dayDdtStart.height + 7)
		.text(txt)
		.attr('dx', 16)
		.attr('text-anchor', 'start');		

}

function createViolationLine(violation, startOfDay){ 
	const dayString =  d3.timeFormat("%Y-%m-%d")(startOfDay),
		  endOfDay  = new Date(parseInt(dayString.substr(0,4)),(parseInt(dayString.substr(5,2)) - 1),parseInt(dayString.substr(8,2)), 23, 59, 0, 0).getTime(),
		  idDay     = 'ResmChartDay-'+ dayString,
		  start     = violation.start < startOfDay ? startOfDay : violation.start,
		  end       = violation.end > endOfDay ? endOfDay : violation.end;
	let   posY      = 0,
		  widthA, typeClass;

	if (!document.getElementById(idDay))
		return;

	switch(violation.type) {
		case 'F5.5':
		case 'F5.6':
		case 'F5.7':
				typeClass = 'dailyRest';
				posY = 11;
				break;
		case 'F5.8':
		case 'F5.9':
		case 'F5.10':
		case 'F5.13':
		case 'F5.14':
				typeClass = 'weeklyRest';
				posY = 9;
				break;
		case 'F5.11':
				typeClass = 'continuousDriving';
				posY = 7;
				break;					
		case 'F5.1':
		case 'F5.2':
				typeClass = 'dailyDriving';
				posY = 5;
				break;
		case 'F5.3':
				typeClass = 'weeklyDriving';
				posY = 3;
				break;
		case 'F5.4':
				typeClass = 'twoWeeklyDriving';
				posY = 1;
				break;
		default:
				typeClass = 'undefined';
	}
			
	const day 	= d3.select('#'+idDay),
		  x 	= d3.scaleTime()
						.domain([startOfDay, endOfDay])
						.range([0, daySize.width - dayMargins.left - dayMargins.right - dayLabel.width]),
		  act = day.select('.ResmChartDayContent').append('g')
						.attr('transform', 'translate(' + x(start) + ',0)');

	if (typeClass !== 'undefined'){
		if (x(end) > daySize.width-dayMargins.left-dayMargins.right)
			{widthA = daySize.width-dayMargins.left-dayMargins.right - x(start);}
		else
			{widthA = x(end) - x(start);}

		act.append('rect')
				.attr('class', 'ResmChartViolationLine-' + typeClass)
				.attr('y', daySize.height - dayMargins.bottom - posY)
				.attr('width', widthA)
				.attr('height', 0.5);
	}

	let icon = day.select('.ResmChartViolationIcon');
	
	if (icon.nodes().length === 0){
		icon = day.append ('g')
					.attr ('class', 'ResmChartViolationIcon')
					.attr('transform', 'translate(' + (dayLabel.width/2-15)  +',15) scale(0.8)');

		icon.append('circle').attr('cy',6).attr('cx',6).attr('r',8).attr('opacity', 0);
		icon.append('path')										
				.attr('d','M6,0C2.687,0,0,2.686,0,6s2.687,6,6,6c3.313,0,6-2.686,6-6S9.313,0,6,0z M6.96,10.303H5.04V8.311h1.92 V10.303z M6.96,6.918H5.04v-5.22h1.92V6.918z');

	
		icon.append('title').text('');
	}

	let title   = icon.select('title');
	title.text(title.text() + violation.type.substring(1) + ': ' + Violation(violation.type, 0, violation.arg).shortDesc + ' (od ' + d3.timeFormat('%Y-%m-%d %H:%M')(violation.start) + ' do ' + d3.timeFormat('%Y-%m-%d %H:%M')(violation.end) + ')\r\n')
		
}
*/