import React from "react";
import * as XLSX from "xlsx/xlsx.mjs";
import ReaderChartDAD from "./Chart.js";

function App() {
 const [activityRecords, setActivityRecords] = React.useState([]);

React.useEffect(() => {
  //console.log(activityRecords);
});

  const readFile = (e) => {
    e.preventDefault();

    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, {type: 'binary', cellDates:true});
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        //const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
        const dataParse = XLSX.utils.sheet_to_json(ws);
        setActivityRecords(CreateActivityRecords(dataParse));

    };
    reader.readAsBinaryString(f);
  }

  return (
    <div>
      <input type="file" onChange={readFile} />
      <div>
        {activityRecords.length > 0 && 
            <ReaderChartDAD activityData={{activityRecords}} /> 
        }

      </div>
    </div>
  );
}

export default App;

function CreateActivityRecords(data){
  /* activity 0: postój, 1: dyspozycyjność, 2: praca, 3: prowadzenie, 9: brak aktywności
     drivingStatus 0: jeden kierowca, 1: załoga, 10: brak aktywności, 11: wpis manulany */

  const activityRecords = [],
        typesOfWork = {
          'CPR': 0,
          'GP' : 0,
          'OC' : 2,
          'PMK': 0,
          'PRA': 2,
          'PŚN': 2,
          'PWP': 0,
          'PWZ': 0,
          'REZ': 1,
          'TAN': 2,
        }

  let previous = {};
  // dodanie do tablicy activityRecords scalonych aktyności (dodany start i koniec, pojazd)
  data.forEach(record => {
    let activityType = record.MinJazdy > 0 ? 3 : typesOfWork[record.RodzajPracy],
        drivingStatus = 0,
        activityStart = record.DataPoczatek.getTime(),
        activityDuration = (activityType === 3 ? record.MinJazdy : record.MinPostoju);

    //jeśli nie ma poprzedniej pozycji (pierwszy rekord)
    if (Object.keys(previous).length === 0){
      previous = {
        activity: activityType,
        drivingStatus,
        end: activityStart + (activityDuration * 60 * 1000),
        start: activityStart,
        time: activityDuration
      };
      return;
    }

		//jeśli poprzednia pozycja jest zgodna z bieżącą idź do następnego rekordu
		if (previous.activity      === activityType && 
        previous.drivingStatus === drivingStatus && 
        previous.end === activityStart) {
          previous.time += activityDuration;
          previous.end += activityDuration * 60 * 1000;
          return;
        }	

    if (previous.end - previous.start > 0)
      activityRecords.push(previous);

    if (previous.end !== activityStart) {
      activityRecords.push({
        activity: 9,
        drivingStatus,
        start: previous.end,
        end: activityStart,
        time: (activityStart - previous.end) / 60 / 1000
      });
    } 

    previous = {
      activity: activityType,
      drivingStatus,
      end: activityStart + (activityDuration * 60 * 1000),
      start: activityStart,
      time: activityDuration      
    };

  });

  return activityRecords;
}